import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import "./about.scss"
import BackButton from "../images/backButton.png"

const ImprintPage = () => {
  return (
    <section className="about">
      <SEO title="Imprint" />
      <div className="padded">
        <Link to="/">
          <img className="back-button" src={BackButton}></img>
        </Link>
        <h1>Impressum</h1>
        <p>
          Die Benützung der Onlinedienste von ARS ELECTRONICA erfolgt unter den
          folgenden allgemeinen Bedingungen:
        </p>
        <h2>AGB</h2>
        <p>
          <a href="https://ars.electronica.art/about/files/2017/06/AGB_Ars_Electronica_Linz_GmbH_Co_KG_2017.pdf">
            AGB Ars Electronica Linz GmbH & Co KG
          </a>
        </p>
        <h2>Inhalt</h2>
        <p>
          Die Benützung der Onlineangebote erfolgt auf eigene Gefahr. ARS
          ELECTRONICA ist um die maximale Richtigkeit der Inhalte bemüht, kann
          jedoch keine Gewähr für Richtigkeit, Vollständigkeit und Aktualität
          übernehmen. Haftungsansprüche gegen ARS ELECTRONICA, welche sich auf
          Schäden materieller oder ideeller Art beziehen, die durch die Nutzung
          oder Nichtnutzung der dargebotenen Informationen bzw. durch die
          Nutzung fehlerhafter und unvollständiger Informationen verursacht
          wurden, sind grundsätzlich ausgeschlossen, sofern seitens ARS
          ELECTRONICA kein nachweislich vorsätzliches oder grob fahrlässiges
          Verschulden vorliegt. Alle auf aec.at gemachten Angebote sind
          freibleibend und unverbindlich. ARS ELECTRONICA behält es sich
          ausdrücklich vor, Teile der Websites oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <h2>Verweise und Links </h2>
        <p>
          Für Inhalte Dritter, auf die mittels direkten oder indirekten
          Verweisen (‘Links’) hingewiesen wird, schließt ARS ELECTRONICA
          jegliche Haftung aus. Auf die aktuelle und zukünftige Gestaltung, die
          Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten
          besteht seitens ARS ELECTRONICA keinerlei Einfluss. ARS ELECTRONICA
          distanziert sich aus diesem Grund ausdrücklich von allen Inhalten
          aller gelinkten/verknüpften Seiten. Dies gilt sowohl für alle im
          Rahmen des Internet-Angebotes von ARS ELECTRONICA gesetzten Links und
          Verweise als auch für Einträge Dritter in von ARS ELECTRONICA
          bereitgestellten Diskussionsforen, Kommentarmöglichkeiten und
          vergleichbaren Services. Für illegale, fehlerhafte oder unvollständige
          Inhalte und insbesondere für Schäden, die aus der Nutzung oder
          Nichtnutzung solcherart dargebotener Informationen entstehen, haftet
          allein der Anbieter der Seite, auf welche verwiesen wurde, nicht
          derjenige, der über Links auf die jeweilige Veröffentlichung lediglich
          verweist.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          ARS ELECTRONICA ist darum bemüht, in allen Publikationen die
          Urheberrechte der verwendeten Texte, Abbildungen, Grafiken,
          Videosequenzen und Tondokumente zu beachten. Sollte dies im Einzelfall
          nicht geglückt sein, bittet ARS ELECTRONICA um entsprechende
          Mitteilung und sichert eine rasche Klärung und Berichtigung zu. Alle
          innerhalb des Internetangebotes genannten und ggf. durch Dritte
          geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
          Bestimmungen des jeweils gültigen Kennzeichenrechts und den
          Besitzrechten der jeweiligen eingetragenen Eigentümer. Das Copyright
          für veröffentlichte, von ARS ELECTRONICA selbst oder deren Auftrag
          durch Vertragspartner erstellte Objekte bleibt allein bei ARS
          ELECTRONICA als dem Autor der Seiten. Eine Vervielfältigung oder
          Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
          anderen elektronischen oder gedruckten Publikationen ist ohne
          ausdrückliche Zustimmung des Autors (ARS ELECTRONICA) nicht gestattet.
        </p>
        <h2>Datenschutz</h2>
        <p>
          <a
            href="https://ars.electronica.art/about/de/datenschutz/"
            target="_blank"
          >
            Mehr zum Datenschutz bei Ars Electronica
          </a>
        </p>
        <p>© Ars Electronica Linz GmbH & Co KG. Alle Rechte vorbehalten.</p>
      </div>
    </section>
  )
}

export default ImprintPage
